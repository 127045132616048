<template>
    <standard-page :definition="$DEFINITIONS.claim" title="New Claim">
        <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'identities-home'}">Identifier</router-link>
      </span>
            <span>
        <router-link :to="{ name: 'claim-requests'}">Claim Requests</router-link>
      </span>
            <span>{{did}}</span>
        </template>
        <template v-slot:content>
            <div class="tab-pane-body">
                <div class="row row-sm mg-t-20 mg-b-20">
                    <div class="col-lg-3 ">
                        <select @change="getTemplate" aria-hidden="true" class="form-control select2-no-search select2-hidden-accessible rounded"
                                data-select2-id="13" data-vv-as="Attribute Type" name="'type'" style="height: 42px" tabindex="-1"
                                v-model="claim.template_type" v-validate="'required'">
                            <option v-bind:value=null>--- Select a Template ---</option>
                            <option v-bind:key="type.id" v-bind:value="type.template" v-for="(type) in claim_templates  ">{{type.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="pd-30 pd-sm-40 bg-gray-100" v-if="claim.statements.length>0">
                    <div class="row row-xs align-items-center mg-b-20" v-for="(statement, index) in claim.statements">
                        <div class="col-md-2 mg-t-5 mg-md-t-0">
                            <input class="form-control rounded" id="did_property" name="did_property" placeholder="Enter Statement Name" required type="text" v-model="statement.name">
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0">
                            <select aria-hidden="true" class="form-control select2-no-search select2-hidden-accessible rounded" data-select2-id="13" tabindex="-1" v-model="statement.type">
                                <option v-bind:value=null>--- Please Select a type ---</option>
                                <option v-bind:key="index" v-for="(type, index) in data_types">{{type}}</option>
                            </select>
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0" v-if="statement.type === 'Text' && statement.options && statement.options.length > 0">
                            <select :data-vv-as="statement.name" :id="'attribute_value_sm1'+index" :name="'attribute_value_sm1'+index" class="form-control custom-select"
                                    v-if="statement.options && statement.options.length >0" v-model="statement.value">
                                <option :key="val" :value="val" v-for="val in statement.options">
                                    {{val}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0" v-else-if="statement.type === 'Text' || statement.type === 'Location'">
                            <input class="form-control rounded" id="property_string_value" name="property_string_value" placeholder="Enter Value" required type="text" v-model="statement.value">
                        </div>
                        <div class="custom-file col-md-2 mg-t-5 mg-md-t-0" v-if="statement.type === 'File'">
                            <input :id="index" :name="statement.name" @change="fileChange" class="custom-file-input" type="file">
                            <label :for="index" class="custom-file-label">{{statement.file_name}}</label>
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0" v-if="statement.type === 'Number' || statement.type === 'Float'">
                            <input class="form-control rounded" id="property_u8_value" min="1" name="property_u8_value" placeholder="Enter Value" required step="1" type="number"
                                   v-model="statement.value">
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0" v-if="statement.type === 'Date'">
                            <input class="form-control rounded" data-vv-as="Expiry" id="property_date_value" name="property_date_value" type="date" v-model="statement.value">
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0" v-if="statement.type === 'Yes/No'">
                            <label class="rdiobox">
                                <input :name="index+'_yesno'" type="radio" v-bind:value=true v-model="statement.value">
                                <span>Yes</span>
                            </label>
                            <label class="rdiobox">
                                <input :name="index+'_yesno'" type="radio" v-bind:value=false v-model="statement.value">
                                <span>No</span>
                            </label>
                        </div>
                        <div class="col-md-3 mg-t-5 mg-md-t-0">
                            <label class="rdiobox">
                                <input :name="index+'_verification'" type="radio" v-bind:value=true v-model="statement.for_verifier">
                                <span>Verification required</span>
                            </label>
                            <label class="rdiobox">
                                <input :name="index+'_verification'" type="radio" v-bind:value=false v-model="statement.for_verifier">
                                <span>Verification not required</span>
                            </label>
                        </div>
                        <div class="col-md mg-t-10 mg-md-t-0" v-if="claim.statements.length> 1">
                            <a @click="removeStatement(index)" class="btn btn-block btn-danger rounded" href="javascript:void(0)"><i class="fa fa-trash-alt"></i></a>
                        </div>
                        <div class="col-md mg-t-10 mg-md-t-0">
                            <a @click="addStatement" class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" v-if="index+1 === claim.statements.length"><i
                                    class="fa fa-plus-square"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row row-sm mg-t-20">
                <div class="col-lg-3 offset-lg-5 mg-t-5">
                    <input :class="{'is-invalid': submitted && errors.has('name')}" class="form-control rounded" data-vv-as="Claim Description" id="name" name="name" placeholder="Claim Description"
                           type="text" v-model="claim.description" v-validate="'required'">
                    <div class="invalid-feedback" v-if="submitted && errors.has('name')">{{ errors.first('name') }}</div>
                </div>
                <div class="col-lg-2 mg-t-5">
                    <button @click="addClaim" class="btn btn-primary w-100 rounded font-weight-bold" type="button"> Submit
                    </button>
                </div>
                <div class="col-lg-2 mg-t-5">
                    <button @click="reset" class="btn btn-secondary w-100 rounded font-weight-bold" type="button"> Reset
                    </button>
                </div>
            </div>

        </template>
    </standard-page>
</template>

<script>
    import EventBus from "@/event-bus";

    export default {
        name: "NewClaim",
        props: ['catalog', 'did'],
        data() {
            return {
                submitted: false,
                claim_templates: [],
                claim: {
                    template_type: null,
                    description: null,
                    statements: [],
                    private: false,
                    file_name: 'Upload',
                    attachments: []
                },
                data_types: ['Text', 'Number', 'Float', 'Yes/No', 'Date', 'File', 'Location'],
                selected_data_type: null,
                file: File,
            };
        },
        mounted() {
            // AmazeUI Datetimepicker
            $('#datetimepicker').datetimepicker({
                format: 'yyyy-mm-dd hh:ii',
                autoclose: true
            });
            this.init();
        },
        methods: {
            async init() {
                this.addStatement();
                await this.getClaimTemplates();
            },
            addStatement() {
                this.claim.statements.push({
                    name: null,
                    type: "Text",
                    value: null,
                    for_verifier: true,
                    is_null: true
                });
            },
            removeStatement(index) {
                this.claim.statements.splice(index, 1);
                if (this.claim.statements[index].type === 'File') {
                    this.claim.attachments = this.claim.attachments.filter(ca => ca.property_name !== this.claim.statements[index].name);
                }
            },
            async getClaimTemplates() {
                try {
                    let {data} = await this.$identity.get(`identity/catalogs/${this.catalog}/templates?template_type=Claim&page=0&per_page=100`);
                    this.claim_templates = data.templates;
                    this.claim.template_type = this.claim_templates.length > 0 ? this.claim_templates[0].template : null;
                    await this.getTemplate();
                } catch (e) {

                }
            },
            async getTemplate() {
                this.claim.statements = [{
                    name: null,
                    type: "Text",
                    value: null,
                    for_verifier: true,
                    is_null: true
                }];
                if (this.claim.template_type) {
                    try {
                        let {data} = await this.$identity.get(`/identity/catalogs/${this.catalog}/templates/${this.claim.template_type}`);
                        this.claim.statements = data.map(property => ({
                            ...property,
                            type: property.fact_type === 'Integer' ? 'Number' : property.fact_type === 'Bool' ? 'Yes/No' : property.fact_type,
                            value: null,
                            for_verifier: true,
                            options: property.options && typeof property.options === 'string' ? property.options.split(';') : [],
                            file_name: 'Upload'
                        }));
                    } catch (e) {
                        this.$toastr.e("Error getting catalog name", "Error");
                    }
                }
            },
            async fileChange(e) {
                let file = e.target.files[0];
                this.claim.statements[e.target.id].file_name = file.name;
                let f = new FormData();
                f.append('file', file);
                try {
                    EventBus.$emit('openLoader');
                    let {data} = await this.$identity.post(`identity/dids/${this.did}/claims/documents`, f, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'BWS ' + localStorage.getItem("token")
                        }
                    })
                    if (data) {
                        this.claim.attachments.push({
                            name: data.name,
                            url: data.url,
                            description: null,
                            hash: data.hash,
                            property_name: e.target.name
                        });
                    }
                } catch (e) {
                    console.error(e)
                } finally {
                    EventBus.$emit('closeLoader');
                }
            },
            async addClaim() {
                this.claim.statements = this.claim.statements.filter(ms => ms.type !== 'File').map(c => ({
                    ...c,
                    type: this.getDatatype(c.type, c.value),
                    value: c.value.toString()
                }));
                this.claim['template'] = this.claim.template_type;
                if (this.claim.description) {
                    try {
                        EventBus.$emit('openLoader');
                        await this.$identity.post(`/identity/dids/${this.did}/claims`, this.claim);
                        this.reset();
                        this.$toastr.s("Claim Successfully created!", 'Success');
                        await this.$router.push({name: 'view-requested-did'});
                    } catch (e) {
                        console.log(e);
                        this.$toastr.s("Failed to create claim!", 'Failed');
                    } finally {
                        EventBus.$emit('closeLoader');
                    }
                } else {
                    this.$toastr.e("Please provide a description first!", 'Failed');
                }
            },
            getDatatype(type, val) {
                switch (type) {
                    case "Text":
                    case "Location":
                        return 'String';
                    case "Float":
                        return 'String';
                    case "Number":
                        if (val >= 0 && val < 256) {
                            return 'U8';
                        } else if (val > 255 && val < 65537) {
                            return 'U16';
                        } else if (val > 65536 && val < 4000001) {
                            return 'U32';
                        } else if (val) {
                            return 'U128';
                        }
                    case "Yes/No":
                        return 'Bool';
                    default:
                        return type;
                }
            },
            reset() {
                this.claim = {
                    description: null,
                    statements: [],
                    private: false
                };
            }
        }
    }
</script>

<style scoped>
</style>